<template>
<div class="px-4 sm:px-6 lg:px-4 xl:px-6 pt-4 pb-4 sm:pb-6 lg:pb-4 xl:pb-6 space-y-4 h-full">

    <show-modal v-if="showQuestion" :closeShowQuestion="closeShowQuestion" :selectedQuestion="selectedQuestion"></show-modal>
    <delete-modal v-if="deleteQuestion" :closeDeleteQuestion="closeDeleteQuestion" :closeDeleteQuestionWithdelete="closeDeleteQuestionWithdelete" :selectedQuestion="selectedQuestion"></delete-modal>
    <update-modal v-if="updateQuestion" :closeUpdateQuestion="closeUpdateQuestion" :selectedQuestion="selectedQuestion"></update-modal>


    <div class="flex h-full w-full" :class="isLoading ? 'hidden' : 'block'">
        <div v-if="modules.length == 0" class="border rounded-lg w-full text-center flex justify-center items-center">
          <el-result
            icon="info"
            title="Aucun module"
            subTitle="Il y a aucun module pour cette année "
          >
          </el-result>
        </div>

        <div v-else class="flex sm:flex-row w-full flex-col h-full">

            <div class="sm:w-64">
                <div class="mb-2">
                    <p class="font-semibold text-sm text-gray-800 text-left ml-2">Modules</p>
                </div>
        
                <div class="rounded-lg">
                  <!-- <el-scrollbar height="413px" class="w-full border rounded-md bg-white"> -->
                    <el-menu
                        class="el-menu-vertical-demo w-full border rounded-lg text-center bg-white"
                        @select="handleSelect"
                        @open="handleOpen"
                        @close="handleClose"
                        :collapse="isCollapse"
                    >
                      <el-scrollbar height="413px" class="w-full rounded-lg bg-white">
                        <el-menu-item  v-for="module in modules" :key="module.id" v-bind:index="module.id">
                          <template #title>{{ module.title  }} </template>
                        </el-menu-item>
                      </el-scrollbar>
                    </el-menu>
                  <!-- </el-scrollbar> -->
                </div>

            </div>


            <div class="flex flex-col w-full sm:ml-6 sm:mt-0  ">
                <div class="inline-flex justify-between items-center">
                    <div class="mb-2">
                      <p class="font-semibold text-sm text-gray-800 text-left ml-2 sm:mt-0 mt-5">Liste des questions</p>
                    </div>
                    <div></div>
                </div>


                <div class="border rounded-lg py-3 h-full">

                  <div class="mb-4 px-4 flex flex justify-between mx-4">
                    <el-select @change="searchQuestions" v-model="selectedCourse" placeholder="Cours" class="w-5/12">
                      <el-option v-for="course in courses" :key="course.title" :value="course.id" :label="course.title">
                      </el-option>
                    </el-select>

                    <el-select @change="searchQuestions" v-model="questiontype" multiple placeholder="Types de questions" class="w-5/12">
                      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  

                  <div :class="isLOadingQuestions ? 'block' : 'hidden'" class="flex items-center justify-center sm:mt-32 w-full">
                    <!--<div class="fulfilling-square-spinner">
                      <div class="spinner-inner"></div>
                    </div>-->
                    <div class="loading">
                      <div class="dot"></div>
                      <div class="dot"></div>
                      <div class="dot"></div>
                      <div class="dot"></div>
                    </div>
                  </div>

                  <div :class="isLOadingQuestions ? 'hidden' : 'block'">
                    <div v-if="questions.length == 0">
                      <div class="flex justify-center items-center h-full p-5 sm:mt-12">
                        <el-result
                          icon="info"
                          :title="selectedModuleId ? 'Aucune question' : 'Aucun module sélectionné'"
                          :subTitle="selectedModuleId ? 'Il y a aucune question pour ce module' : 'Veuillez sélectionner un module'"
                        >
                        </el-result>
                      </div>
                    </div>

                    <div v-else>
                      <div class="mx-4 mb-3">
                        <el-scrollbar height="280px" class="w-full rounded-lg bg-white">
                          <div v-for="question in questions" :key="question.id" class="flex justify-between items-center border rounded-md p-1 my-1 mx-4 transition duration-500 transform-gpu hover:scale-105 hover:bg-red-50 hover:border-red-400">
                              <div>
                                  <el-popover effect="light" trigger="hover" placement="right" :width="400">
                                    <template #default>
                                      <p>{{ question.question }}</p>
                                    </template>
                                    <template #reference>
                                      <div class="name-wrapper">
                                        <el-tag>
                                          <span>{{ question.question.split(" ")[0] }} {{ question.question.split(" ")[1] }} {{ question.question.split(" ")[2] }} </span>
                                          <span v-if="question.question.split(' ')[2]"> ...</span>
                                        </el-tag>
                                      </div>
                                    </template>
                                  </el-popover>
                              </div>

                              <div class="flex sm:flex-row flex-col justify-center items-center">
                                <div class="text-center">
                                  <span v-if="question.type == 'qcs'" class="text-sm sm:mx-10"> Qcu </span>
                                  <span v-if="question.type == 'qcm'" class="text-sm sm:mx-10"> Qcm </span>
                                  <span v-if="question.type == 'qroc'" class="text-sm sm:mx-10"> Qroc </span>
                                  <span v-if="question.type == 'clinicalcas'" class="text-sm sm:mx-10"> Cas clinique </span>
                                </div>
                                <div class="flex item-center justify-center">
                                  <el-popconfirm @confirm="updatefreequestion(question.id,question.type,question.isFree)" @cancel="question.isFree = !question.isFree" title="Voulez vous vraiment changer l'affichage ?" icon-color="gray"   confirm-button-text="Oui" cancel-button-text="Non">
                                    <template #reference>
                                      <el-switch v-model="question.isFree" class="mr-5" ></el-switch>
                                    </template>
                                  </el-popconfirm>
                                    
                                    <div @click="OpenShowQuestion(question.id,question.type)" class="w-4 mr-2 transform hover:text-red-400 hover:scale-110">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                        </svg>
                                    </div>
                                    <div @click="OpenUpdateQuestion(question.id,question.type)" class="w-4 mr-2 transform hover:text-red-400 hover:scale-110">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                        </svg>
                                    </div>
                                    <div @click="OpendeleteQuestion(question.id,question.type)" class="w-4 mr-2 transform hover:text-red-400 hover:scale-110">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                        </svg>
                                    </div>
                                </div>
                              </div>
                          </div>
                        </el-scrollbar>
                      </div>

                      <ul class="relative z-0 inline-flex rounded-md -space-x-px w-full justify-center ">
                        <li>
                          <button :disabled="prevp === null" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                            @click.prevent="prevPage"
                          >
                            <span class="sr-only">Previous</span>
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                            </svg>
                          </button>
                        </li>
                        <li v-for="page in lastp" :key="page">
                          <button class=" hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                            :class=" page === currentp ? 'z-10 bg-red-50 border-red-400 text-red-400' : 'bg-white border-gray-300 text-gray-700'"

                            @click.prevent="refine(page)"
                          >
                            {{ page }}
                          </button>
                        </li>

                        <li>
                          <button class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                            @click.prevent="nextPage"
                          >
                            <span class="sr-only">Next</span>
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                            </svg>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>

            </div>
        </div>
    </div>

    <div
      :class="isLoading ? 'block' : 'hidden'" class="flex items-center justify-center h-full w-full">
      <!--<div class="fulfilling-square-spinner">
        <div class="spinner-inner"></div>
      </div>-->
      <div class="loading">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>

</div>

</template>

<script>
import axios from 'axios'
import DeleteModal from '../dashboard/QuestionsModals/DeleteModal.vue'
import ShowModal from '../dashboard/QuestionsModals/ShowModal.vue'
import UpdateModal from '../dashboard/QuestionsModals/UpdateModal.vue'
import { ElMessage } from 'element-plus'

export default {
    components: {
        DeleteModal,
        ShowModal,
        UpdateModal,
    },

    data () {
        return {
            value :false,
            isLoading : false,
            isLOadingQuestions : false,
            showQuestion : false,
            updateQuestion : false,
            deleteQuestion : false,
            modules: [],
            courses: [],
            selectedCourse: null,
            questions :[],
            questiontype: null,
            selectedModuleId : null,
            selectedQuestion:{
              id: null,
              type: null,
            },
            options: [
              {
                value: 'qcm',
                label: 'Qcm',
              },
              {
                value: 'qcs',
                label: 'Qcu',
              },
              {
                value: 'qroc',
                label: 'Qroc',
              },
              {
                value: 'clinicalcas',
                label: 'Cas clinique',
              },
            ],


            nextp : null,
            prevp : null,
            lastp : null,
            currentp : null,

            getTypeForPag : ['clinicalcas','qcm','qroc','qcs']
        }
    },

    mounted () {
        this.isLoading = true;
       
        axios
        .get(process.env.VUE_APP_API_BASE_URL+"/moduleofyear/"+this.$route.query.year, {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(({ data }) => {
          this.modules = data.modules;
          this.isLoading = false;
        })
        .catch(() => {
          this.errorm();
          this.isLoading = false;
        });

        if( (this.$route.query.cours != null) && (this.$route.query.module != null) && (this.$route.query.type != null) ){
          this.handleSelect(this.$route.query.module)
          this.selectedCourse = this.$route.query.cours
          this.isLOadingQuestions = true
          let choice = [];
          choice[0]  = this.$route.query.type;
          this.questiontype = choice
          axios.get( process.env.VUE_APP_API_BASE_URL+"/questionsofmodule/"+this.selectedModuleId+"/"+JSON.stringify(choice)+"/"+this.selectedCourse, {
            headers: {
              Authorization: "Bearer" + this.isToken,
            },
          })
          .then(({ data }) => {
            this.questions = data.result.data;
            this.lastp = data.result.last_page;
            this.nextp = data.result.next_page_url;
            this.currentp = data.result.current_page;
            this.isLOadingQuestions = false;
          })
          .catch(() => {
            this.errorm();
            this.isLOadingQuestions = false;
          });

          
        }
    },

    methods: {
      updatefreequestion(id,type,isFree){
        this.questions.forEach(element => {
          if(element.id === id && element.type === type){
            let free;
            if (isFree) {free = 1;} else {free = 0;}
            axios
                .put(process.env.VUE_APP_API_BASE_URL+"/updatefreequestions/" + id +"/"+type,{
                  isFree : free
                },
                
                {
                  headers: {
                    Authorization: "Bearer" + this.isToken,
                  },
                })
                .then(({ data }) => {
                  
                })
                .catch(() => {
                  
                });
          }
          
        });

        
      },



      loadCourses(id) {
      this.selectedCourse = null;
      this.isLoading = true;
      axios
        .get(process.env.VUE_APP_API_BASE_URL+"/courseofmodule/" + id, {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(({ data }) => {
          this.courses = data.courses;
          this.isLoading = false;
        })
        .catch(() => {
          this.errorm();
          this.isLoading = false;
        });
        },

        
        handleSelect(key) {
            this.isLOadingQuestions = true;
            this.selectedModuleId = key;
            this.loadCourses(this.selectedModuleId)
            this.prevp = null;
            this.nextp = null;
            this.currentp = null;
            this.lastp = null;
            let courseID 
            if (this.selectedCourse === null) {courseID = -1;}else{courseID = this.selectedCourse}
            axios.get( process.env.VUE_APP_API_BASE_URL+"/questionsofmodule/"+key+"/"+JSON.stringify(['clinicalcas','qcm','qroc','qcs'])+"/"+courseID, {
              headers: {
                Authorization: "Bearer" + this.isToken,
              },
            })
            .then(({ data }) => {
              this.questions = data.result.data;
              this.lastp = data.result.last_page;
              this.nextp = data.result.next_page_url;
              this.currentp = data.result.current_page;
              this.isLOadingQuestions = false;
            })
            .catch(() => {
              this.errorm();
              this.isLOadingQuestions = false;
            });
      },
      

      searchQuestions(){ 
        this.prevp = null;
        this.nextp = null;
        this.currentp = null;
        this.lastp = null;
        let choice = [];
        this.questiontype.forEach(element => {
          choice = [...choice,element]
        });
       

        if(choice.length == 0){
          choice  =  ['clinicalcas','qcm','qroc','qcs'];
        }

        this.getTypeForPag = choice;

        if(this.selectedModuleId){
          this.isLOadingQuestions = true
          axios.get( process.env.VUE_APP_API_BASE_URL+"/questionsofmodule/"+this.selectedModuleId+"/"+JSON.stringify(choice)+"/"+this.selectedCourse, {
            headers: {
              Authorization: "Bearer" + this.isToken,
            },
          })
          .then(({ data }) => {
            this.questions = data.result.data;
            this.lastp = data.result.last_page;
            this.nextp = data.result.next_page_url;
            this.currentp = data.result.current_page;
            this.isLOadingQuestions = false;
          })
          .catch(() => {
            this.errorm();
            this.isLOadingQuestions = false;
          });
        }
      },

      nextPage(){
        this.isLOadingQuestions = true;
        axios.get(this.nextp, {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(({ data }) => {
          this.questions = data.result.data;
          this.lastp = data.result.last_page;
          this.nextp = data.result.next_page_url;
          this.prevp = data.result.prev_page_url;
          this.currentp = data.result.current_page;
          this.isLOadingQuestions = false;
        })
        .catch(() => {
          this.errorm();
          this.isLOadingQuestions = false;
        });
      },

      prevPage(){
        this.isLOadingQuestions = true;
        axios.get(this.prevp, {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(({ data }) => {
          this.questions = data.result.data;
          this.lastp = data.result.last_page;
          this.nextp = data.result.next_page_url;
          this.prevp = data.result.prev_page_url;
          this.currentp = data.result.current_page;
          this.isLOadingQuestions = false;
        })
        .catch(() => {
          this.errorm();
          this.isLOadingQuestions = false;
        });
      },

      refine(page){
        this.isLOadingQuestions = true;
        let courseID 
            if (this.selectedCourse === null) {courseID = -1;}else{courseID = this.selectedCourse}
        axios.get(process.env.VUE_APP_API_BASE_URL+"/questionsofmodule/"+this.selectedModuleId+"/"+JSON.stringify(this.getTypeForPag)+"/"+courseID+"?page="+page, {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(({ data }) => {
          this.questions = data.result.data;
          this.lastp = data.result.last_page;
          this.nextp = data.result.next_page_url;
          this.prevp = data.result.prev_page_url;
          this.currentp = data.result.current_page;
          this.isLOadingQuestions = false;
        })
        .catch(() => {
          this.errorm();
          this.isLOadingQuestions = false;
        });
      },


    OpenShowQuestion(id,type){
      this.showQuestion = true;
      this.selectedQuestion.id = id;
      this.selectedQuestion.type = type;
    },

    OpenUpdateQuestion(id,type){
      this.updateQuestion = true;
      this.selectedQuestion.id = id;
      this.selectedQuestion.type = type;
    },

    OpendeleteQuestion(id,type){
      this.deleteQuestion = true;
      this.selectedQuestion.id = id;
      this.selectedQuestion.type = type;
    },

     closeDeleteQuestionWithdelete(){
      this.questions = this.questions.filter((tab) => tab.id !== this.selectedQuestion.id);
      this.closeDeleteQuestion();
      this.succesDelete();
    },

    closeDeleteQuestion(){
      this.deleteQuestion = false;
      this.selectedQuestion.id = null;
      this.selectedQuestion.type = null;
    },
    closeShowQuestion(){
      this.showQuestion = false;
      this.selectedQuestion.id = null;
      this.selectedQuestion.type = null
    },
    closeUpdateQuestion(){
      this.updateQuestion = false;
      this.selectedQuestion.id = null;
      this.selectedQuestion.type = null;
      this.succesUpdate();
    }
    },

    computed: {
      isToken() {
        return this.$store.getters.get_token;
      },
    },
    setup() {
  
    const succesUpdate = () => {
      ElMessage({
        message: 'La question a été modifiée avec succès.',
        type: 'success',
      })
    }
    const succesDelete = () => {
      ElMessage({
        message: 'La question a été supprimée avec succès.',
      })
    }
    const errorm = () => {
      ElMessage.error('Une erreur est survenue !')
    }
    return {
      succesUpdate,
      succesDelete,
      errorm
    }
  },
}
</script>

<style>
.fulfilling-square-spinner,
.fulfilling-square-spinner * {
  box-sizing: border-box;
}

.fulfilling-square-spinner {
  height: 100px;
  width: 100px;
  position: relative;
  border: 4px solid #f87171;
  animation: fulfilling-square-spinner-animation 4s infinite ease;
}

.fulfilling-square-spinner .spinner-inner {
  vertical-align: top;
  display: inline-block;
  background-color: #f87171;
  width: 100%;
  opacity: 1;
  animation: fulfilling-square-spinner-inner-animation 4s infinite ease-in;
}

@keyframes fulfilling-square-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fulfilling-square-spinner-inner-animation {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}
</style>