<template>
    <div >
        <div v-show="AddQuestionForClinicalCaseModal" >
          <AddQuestionForClinicalCaseExamModal @add-question-clinicalcas="addQuestionOnClinicalCase" :closeAddQuestionForClinicalCaseModal="closeAddQuestionForClinicalCaseModal" />
        </div>
        <div class="fixed inset-0 z-30 bg-red-200" style="opacity: 0.5" aria-hidden="true"></div>
            <!--  update modal   -->
        <div class="z-40 inset-0 fixed flex justify-center items-center overflow-y-auto">
          <div v-show="AddQuestionForClinicalCaseModal">
          <AddQuestionForClinicalCaseExamModal @add-question-clinicalcas="addQuestionOnClinicalCase" :closeAddQuestionForClinicalCaseModal="closeAddQuestionForClinicalCaseModal" />
          </div>

          <div class=" flex flex-col w-16/12 sm:w-5/6 lg:w-1/2 max-w-md mx-auto rounded-lg border border-gray-300 shadow-xl h-4/5">
            <div class=" flex flex-row justify-between items-center px-3 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
              <p class="font-semibold text-sm text-gray-800">Modifier une question</p>
              <svg @click="closeUpdateQuestion" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </div>
            <div class="flex flex-col px-6 py-5 bg-gray-50">


              <div :class="isLoading ? 'block' : 'hidden'" class="flex items-center justify-center h-full w-full">
                <!--<div class="fulfilling-square-spinner-show">
                  <div class="spinner-inner-show"></div>
                </div>-->
                <div class="loading">
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                </div>
              </div>


              <div :class="isLoading ? 'hidden' : 'block'"  class=" flex flex-col sm:flex-row items-center text-center text-sm p-3 sm:space-x-5">   
                <div class="w-full">

                  <div class="flex flex-col justify-center items-center w-full">
                  <div class="flex sm:flex-row flex-col justify-between w-full">
                    <select @change="loadCourses($event)" class="w-full p-2 m-1 bg-white border border-gray-200 rounded-lg shadow-sm">
                      <option value="" disabled selected>Module</option>
                      <option
                        v-for="module in modules"
                        :key="module.id"
                        :value="module.id"
                      >
                        {{ module.title }} - {{ module.year }} année
                      </option>
                    </select>
                    <select @change="getIdCourse($event)" class="w-full p-2 m-1 bg-white border border-gray-200 rounded-lg shadow-sm">
                      <option value="" disabled selected>
                        Cours
                      </option>
                      <option v-for="course in courses" :key="course.id" :value="course.id">{{ course.title }}</option>
                    </select>
                    <div class="w-1/3 p-1">
                    <el-date-picker size="meduim" class="max-w-full" v-model="questionDate" type="month" value-format="x" placeholder="Sélectionnez une année"></el-date-picker>
                    </div>
                  </div>
                  <div class="flex sm:flex-row flex-col justify-between w-full">
                      <select v-model="source_id" class="w-3/5 p-2 m-1 bg-white border border-gray-200 rounded-lg shadow-sm">
                        <option value="" disabled selected>Source</option>
                        <option v-for="source in sources"
                          :key="source.id"
                          :value="source.id">
                          {{ source.title }}
                        </option>
                      </select>
                      <div class="w-2/5 flex flex-row">
                          <div class="flex flex-col justify-center items-center mx-2">
                            <div>Activer</div>
                            <el-switch class="mt-2" v-model="showQuestion"> </el-switch>
                          </div>
                           <div class="flex flex-col justify-center items-center mx-2">
                            <div>Certifier</div>
                            <el-switch class="mt-2" v-model="CertifiedQuestion"> </el-switch>
                          </div>
                    </div>
                      
                    </div>
                <div class="w-full flex flex-row">
                  <div class="w-1 p-2 m-1 items-center">
            
                    <el-tag
                      v-for="tag in dynamicTags"
                      :key="tag"
                      closable
                      type="danger"
                      :disable-transitions="false"
                      @close="handleClose(tag)"
                    >
                        {{ tag }}
                    </el-tag>
                    <el-input
                      v-if="inputVisible"
                      ref="saveTagInput"
                      v-model="inputValue"
                      class="input-new-tag"
                      size="mini"
                      @keyup.enter="handleInputConfirm"
                      @blur="handleInputConfirm"
                    >
                    </el-input>
                    <el-button v-else class="button-new-tag" size="small" @click="showInput"
                    >+ New Tag</el-button>

                  </div>
                </div>

                  <div class="sm:flex w-full">
                    <textarea v-model="field" placeholder="Veuillez introduire votre question ..." name="" id="" class="w-full p-2 m-1 bg-white border border-gray-200 rounded-lg shadow-sm"></textarea>
                  </div>
                  <div v-show="questionType=='qroc'" class="sm:flex w-full">
                    <textarea v-model="responsetype" placeholder="Veuillez introduire la réponse type" class="w-full p-2 m-1 bg-white border border-gray-200 rounded-lg shadow-sm"></textarea>
                  </div>
                  <div v-show="questionType=='qcs/qcm'" class="sm:flex sm:flex-col w-full">

                    <button @click="addSupposition=true" class="py-2 px-4 m-1 mx-auto text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
                      Ajouter une supposition
                    </button>
                    <div v-show="addSupposition" class="flex flex-col m-1 border rounded-lg p-3">
                      <div class="flex flex-row justify-center items-center">
                        <input v-model="supposition" type="text" placeholder="Veuillez introduire une supposition" class="w-full p-2 m-1 bg-white border border-gray-200 rounded-lg shadow-sm">
                        <el-switch v-model="state" class="mx-2"> </el-switch>
                      </div>
                      <button @click="confirmSupposition" class="py-2 px-4 mt-2 mx-auto text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
                        Confirmer l'ajout
                      </button>
                    </div>
                    <el-scrollbar max-height="128px" class="my-1">
                      <div
                        class="bg-gray-200 my-1 mx-3 p-1 rounded-lg flex flex-row justify-between items-center" v-for="supposition in suppositions" :key="supposition.id">
                        <div class="flex flex-row items-center">
                          <div
                            :class="
                              supposition.state == '1' ? 'bg-green-400' : 'bg-red-400'
                            "
                            class="h-2 w-2 m-2 rounded"
                          ></div>
                          {{ supposition.proposal }}
                        </div>
                        <svg
                          @click="onDeleteSupp(supposition.id)"
                          class="w-4 h-4"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6 18L18 6M6 6l12 12"
                          ></path>
                        </svg>
                      </div>
                    </el-scrollbar>
                  </div>
                  
                  <div v-show="questionType=='clinicalcas'" class="sm:flex sm:flex-col w-full">
                    <button @click="AddQuestionForClinicalCaseModal=true" class="py-2 px-4 mt-1 mx-auto text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
                      Ajouter une question
                    </button>
                    <!-- <el-scrollbar max-height="128px" class="my-1"> -->
                    <el-collapse  v-model="activeNames" @change="handleChange" class="bg-white border rounded-lg my-2 shadow-sm">
                      <el-collapse-item class="px-4"  v-for="(cas,index) in listallnewclinicalcase" :key="cas.position" :title="'Question ' + (index+1)"  >
                        <div class="text-left">
                          <div v-if="cas.image != null">
                              <el-image class="rounded-lg" style="width: 100px; height: 100px"
                                :src="cas.image.toString()"
                                :preview-src-list="[cas.image.toString()]">
                              </el-image>
                          </div>
                          <div class="border rounded-lg  mb-3">
                            <div class="border-b px-2 py-1">
                              Question 
                            </div>
                            <div class="p-2">
                              {{ cas.question }}
                            </div>
                          </div>

                          <div v-if="cas.type== 'qcm' || cas.type== 'qcs'" class="border rounded-lg">
                            <div class="px-2 py-1">
                              Suppositions 
                            </div>
                            <div>
                              <div class="flex justify-between items-center border-t py-1 px-2" v-for="proposal in cas.proposals" :key="proposal.id">
                                <div>
                                  {{proposal.proposal}}
                                </div>
                                <div v-if="proposal.state == '1' " class="h-2 w-2 bg-green-400 rounded-full"></div>
                                <div v-else class="h-2 w-2 bg-red-400 rounded-full"></div>
                              </div>
                            </div>
                          </div>

                          <div v-if="cas.type== 'qroc'" class="border rounded-lg">
                            <div class="border-b px-2 py-1">
                              Réponse type 
                            </div>
                            <div class="p-2">
                              {{cas.response}}
                            </div>
                          </div>

                          <div class="w-full text-center">
                            <button @click="DeleteQuestionClinicalcas(cas.id)" class=" text-sm pt-4 transition duration-500 transform-gpu hover:scale-110 ">
                              <el-icon color="#f87171" :size="18">
                                <delete />
                              </el-icon>
                            </button>
                          </div>

                        </div>
                      </el-collapse-item>
                    </el-collapse>
                
                    <!-- </el-scrollbar> -->
                  </div>
                  <div class=" w-full">
                    <QuillEditor theme="snow" toolbar="full" contentType="html" ref="myQuillEditor" v-model:content="explanation" />
                    <!-- <textarea v-model="explanation" placeholder="Veuillez introduire l'explication" class="w-full p-2 m-1 bg-white border border-gray-200 rounded-lg shadow-sm"></textarea> -->
                  </div>
                  <div>
                    <form @submit.prevent="setImageExplication" class="sm:w-1/5 w-full sm:mr-2 mt-6">
                      <div class="w-full bg-white border-gray flex flex-row ">
                        <label
                          class="container mx-auto  p-4 bg-white max-w-sm rounded-lg overflow-hidden border hover:shadow-2xl transition duration-300">
                          <img class="rounded-lg w-full" :src="imageExplicationUrl" alt="" />
                          <input v-if="imageExplicationFile === '' && imageExplicationUrl === ''" type="file" name="image" @change="getImageExplication" hidden />
                          <button v-else @click="cancelExp" class="absolute"></button>
                        </label>
                      </div>
                    </form>
                  </div>

                </div>


                </div>
              </div>
            </div>
            <div
              class=" flex flex-row items-center justify-between px-6 py-3 bg-white border-t border-gray-200 rounded-bl-lg rounded-br-lg "
            >
              <button
                @click="closeUpdateQuestion"
                class="font-semibold text-gray-600 text-sm"
              >
                Annuler
              </button>
              <button @click="updateQuestion" class=" text-sm px-4 text-yellow-500 font-semibold bg-white rounded"
              >
                Modifier
              </button>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import AddQuestionForClinicalCaseExamModal from '../../dashboard/AddQuestionForClinicalCaseExamModal.vue'
import { Delete } from '@element-plus/icons';
import { useImageUpload } from "@/composables/useImageUpload.js";
import store from "../../../store.js";

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Quill from 'quill'
Quill.register('@vueup/vue-quil', QuillEditor)


export default {
   components: {
    QuillEditor,
     Delete,
    AddQuestionForClinicalCaseExamModal
  },

    data () {
    return {
      explication_img : '',

      isLoading : false,
      SelectedQuestion : null,
      questionType : '',
      source_id:'',
      sources: [],
      modules: [],
      courses: [],
      courseId: null,

      field: '',
      showQuestion: true,
      CertifiedQuestion : false,
      explanation:null,
      questionDate:0,
      //suppositions
      addSupposition: false,
      supposition: null,
      state: true,
      suppositions: [],
      CountState: 0 ,
      responsetype: null,
      position : 1,
  
      newSupposition: [],

      newclinicalcase: [],
      allnewclinicalcase:[],

      AddQuestionForClinicalCaseModal : false,
      clinicalcase: [],

      dynamicTags: [],
      inputVisible: false,
      inputValue: '',
    }
  },
    props:{
        closeUpdateQuestion:Function,
        selectedQuestion:Object,

    },
    

  mounted() {

    if(this.selectedQuestion.type == 'clinicalcas'){
      this.isLoading = true;
      axios
      .get(process.env.VUE_APP_API_BASE_URL+"/getclinicalcas/"+this.selectedQuestion.id, {
        headers: {
          Authorization: "Bearer" + this.isToken,
        },
      })
      .then(({ data }) => {
        this.imageExplicationUrl = data.clinicalcases.explanation_image
        this.dynamicTags = JSON.parse(data.clinicalcases.tags);
        this.field = data.clinicalcases.question;
        this.courseId = data.clinicalcases.course_id;
        this.explanation = data.clinicalcases.explanation;
        document.querySelector(".ql-editor").innerHTML = data.clinicalcases.explanation;
        this.questionDate = data.clinicalcases.year;
        this.questionType = 'clinicalcas';
        if (data.clinicalcases.show == "1") {this.showQuestion = true;}else{this.showQuestion = false;}
        if (data.clinicalcases.Verified == "1") {this.CertifiedQuestion = true;}else{this.CertifiedQuestion = false;}
        Object.values(data.clinicalcases.questions).forEach((question) => {
             this.clinicalcase = [...this.clinicalcase, {
              id : question.id,
              question : question.question,
              response : question.response, 
              proposals: question.propositions,
              position: question.position,
              show: question.show,
              type: question.type,
              image: question.image,
              }];
          });
        this.allnewclinicalcase = this.clinicalcase;
        this.isLoading = false;
        
      })
      .catch((e) => {
        
        this.isLoading = false;
      });

    }else{
      this.isLoading = true;
      axios
      .get(process.env.VUE_APP_API_BASE_URL+"/getquestion/"+this.selectedQuestion.id, {
        headers: {
          Authorization: "Bearer" + this.isToken,
        },
      })
      .then(({ data }) => {
        this.source_id = data.result.source_id
        this.imageExplicationUrl = data.result.explanation_image
        this.explication_img = data.result.explanation_image
        this.dynamicTags = JSON.parse(data.result.tags);
        this.questionType = data.result.type;
        this.courseId = data.result.course_id;
        this.field = data.result.question;
        this.explanation = data.result.explanation;
        document.querySelector(".ql-editor").innerHTML = data.result.explanation;
        this.questionDate = data.result.year;
        if (data.result.show == "1") {this.showQuestion = true;}else{this.showQuestion = false;}
        if (data.result.Verified == "1") {this.CertifiedQuestion = true;}else{this.CertifiedQuestion = false;}
        if(data.result.type === "qcm" || data.result.type == "qcs")
        {
          this.questionType = "qcs/qcm";
          
          Object.values(data.result.propositions).forEach((supposition) => {
             this.suppositions = [...this.suppositions, {
              id : supposition.id,
              proposal: supposition.proposal,
              state: supposition.state,
              }];
              if (supposition.state === "1") {
                this.CountState = this.CountState + 1;
              }
          });

        }
        if(data.result.type === "qroc"){
          this.responsetype = data.result.response;
        }
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      });

    }
     
    axios
      .get(process.env.VUE_APP_API_BASE_URL + "/source", {
        headers: {
          Authorization: "Bearer" + this.isToken,
        },
      })
      .then(({ data }) => {
        this.sources = data.sources;
      })
      .catch((e) => {
        this.errormessage();
        this.isLoading = false;
      });
    axios
      .get(process.env.VUE_APP_API_BASE_URL+"/module", {
        headers: {
          Authorization: "Bearer" + this.isToken,
        },
      })
      .then(({ data }) => {
        this.modules = data.modules;
      })
      .catch(() => {
        this.isLoading = false;
      });
   
  },
   computed: {
    isToken() {
      return this.$store.getters.get_token;
    },
    listallnewclinicalcase() {
      return this.allnewclinicalcase.sort((a,b) =>  a.position-b.position );
    }

  },
    methods: {

    cancelExp(){
        this.cancelImageExplication()
        this.imageExplicationUrl = this.explication_img
    },

      // Clinical case methodes 
    addQuestionOnClinicalCase(question){
      this.newclinicalcase = [...this.newclinicalcase,question];
      this.allnewclinicalcase = [...this.allnewclinicalcase,question];
    },

    closeAddQuestionForClinicalCaseModal(){
      this.AddQuestionForClinicalCaseModal = false
    },

    // Recuperer le cours 
    getIdCourse(event) {
      this.courseId = event.target.value;
    },
    loadCourses(event) {
      this.isLoading = true;
      axios
        .get(process.env.VUE_APP_API_BASE_URL+"/courseofmodule/" + event.target.value, {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(({ data }) => {
          this.isLoading = false;
          this.courses = data.courses;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    // Suppositions
    confirmSupposition() {
      if (!this.supposition) {
        alert("veuillez entrer une supposition");
        return;
      }
      let nstate = null;
      if (this.state === true) { nstate ="1";} else {nstate = "0";}
      if (this.state === true) {this.CountState = this.CountState + 1;}

      this.suppositions = [...this.suppositions, {
        id : Math.floor(Math.random() * 1000),
        proposal: this.supposition,
        state: nstate,
      }];

      this.newSupposition = [...this.newSupposition, this.suppositions[this.suppositions.length - 1]];

      this.supposition = "";
      this.state = false;
    },

    onDeleteSupp(id) {
      
      this.suppositions = this.suppositions.filter(
        (supposition) => supposition.id !== id
      );
      this.newSupposition = this.newSupposition.filter(
        (supposition) => supposition.id !== id
      );
      
      this.CountState = 0;
      this.suppositions.forEach((supposition) => {
        if (supposition.state === "1") {
          this.CountState = this.CountState + 1;
        }
      });
    },

    DeleteQuestionClinicalcas(id){
      this.clinicalcase = this.clinicalcase.filter(
        (item) => item.id !== id
      );
      this.allnewclinicalcase = this.allnewclinicalcase.filter(
        (item) => item.id !== id
      );
      this.newclinicalcase = this.newclinicalcase.filter(
        (item) => item.id !== id
      );

    },

    // Update question 
    updateQuestion(){
      let show , certified;
      if(this.showQuestion){show = '1';}else{show='0'}
      if(this.CertifiedQuestion){certified = '1';}else{certified='0'}
      if (this.questionType == 'qcs/qcm') {
        if(this.CountState !== 0) {
            let typeM = "";
            if (this.CountState === 1) {
              typeM = "qcs";
            } else {
              typeM = "qcm";
            }
        
         axios
        .put(process.env.VUE_APP_API_BASE_URL+"/question/" + this.selectedQuestion.id , {
              source_id :this.source_id,
              course_id: this.courseId,
              question: this.field,
              explanation: this.explanation,
              year: this.questionDate,
              type: typeM,
              response: this.responsetype,
              show: show,
              suppositions: JSON.stringify(this.suppositions),
              newsupposition: this.newSupposition,
              tags : JSON.stringify(this.dynamicTags),
              Verified : certified
        },{
                  headers: {
                  Authorization: "Bearer" + this.isToken,
              },
        })
        .then(() => {
        })
        .catch(() => {
          
        });
        this.CountState = 0;
        }
      }

      if (this.questionType == 'qroc') {
        
        axios
        .put(process.env.VUE_APP_API_BASE_URL+"/question/" + this.selectedQuestion.id , {
              source_id: this.source_id,
              course_id: this.courseId,
              question: this.field,
              explanation: this.explanation,
              year: this.questionDate,
              type: this.questionType,
              response: this.responsetype,
              show: show,
              tags : JSON.stringify(this.dynamicTags),
              Verified : certified
        },{
                  headers: {
                  Authorization: "Bearer" + this.isToken,
              },
        })
        .then(() => {
        })
        .catch(() => {
          
        });
      }
      if (this.questionType == 'clinicalcas') {

        axios
        // .put(process.env.VUE_APP_API_BASE_URL+"/clinicalcase/" + this.selectedQuestion.id , {
        .put(process.env.VUE_APP_API_BASE_URL + "/clinicalcase/" + this.selectedQuestion.id, {

              source_id: this.source_id,
              course_id: this.courseId,
              question: this.field,
              explanation : this.explanation,
              year: this.questionDate,
              type: this.questionType,
              show: show,
              oldclinicalcase: JSON.stringify(this.clinicalcase),
              newclinicalcase: this.newclinicalcase,
              tags : JSON.stringify(this.dynamicTags),
              Verified : certified
        },{
                  headers: {
                  Authorization: "Bearer" + this.isToken,
              },
        })
        .then(({data}) => {
          console.log(data.questions);
        })
        .catch((e) => {
          console.log(e);
        });
        

      }
      if (this.explication_img != this.imageExplicationUrl ){
        this.setImageExplication(this.selectedQuestion.id, this.questionType )
      }

      this.closeUpdateQuestion();
    },
        handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1)
    },

    showInput() {
      this.inputVisible = true
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },

    handleInputConfirm() {
      const inputValue = this.inputValue
      if (inputValue) {
        this.dynamicTags.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },

    

    },

    setup() {
      let { getImageExplication, imageExplicationUrl, imageExplicationFile, cancelImageExplication } = useImageUpload();

      function setImageExplication(id,type) {
        let form = new FormData();
        form.append("image", imageExplicationFile.value);

        if (type === 'clinicalcas'){
          axios
            .post(process.env.VUE_APP_API_BASE_URL+"/setExplanationImageCC/" +
              id,
              form,
              {
                headers: {
                  Authorization: "Bearer" + store.getters.get_token,
                },
              }
            )
            .then(() => {
              cancelImageExplication();
            })
            .catch(() => {
            });
        } else if (type === 'qcs/qcm' || type === 'qroc'){
        axios
          .post(process.env.VUE_APP_API_BASE_URL+"/setExplanationImage/" +
            id,
            form,
            {
              headers: {
                Authorization: "Bearer" + store.getters.get_token,
              },
            }
          )
          .then(() => {
            cancelImageExplication();
          })
          .catch(() => {
          });
        }
        this.imageExplicationFile = ""
        this.imageExplicationUrl = ""
      }

      return {getImageExplication, imageExplicationUrl, imageExplicationFile, cancelImageExplication, setImageExplication };
    }



};
</script>
<style>
.fulfilling-square-spinner-show,
.fulfilling-square-spinner-show * {
  box-sizing: border-box;
}

.fulfilling-square-spinner-show {
  height: 70px;
  width: 70px;
  position: relative;
  border: 4px solid #f87171;;
  animation: fulfilling-square-spinner-animation 4s infinite ease;
}

.fulfilling-square-spinner-show .spinner-inner-show {
  vertical-align: top;
  display: inline-block;
  background-color: #f87171;
  width: 100%;
  opacity: 1;
  animation: fulfilling-square-spinner-inner-animation 4s infinite ease-in;
}

@keyframes fulfilling-square-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fulfilling-square-spinner-inner-animation {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>